import { Directive, HostListener, Input } from "@angular/core";
import { AbstractControl, FormArray, FormControl, FormGroup } from "@angular/forms";
import { BaseComponent } from "@abstract/BaseComponent";
import { ComponentCheckCanQuit } from "@services/check-can-quit";
import { KeyLang } from "@locale/index";
import { Log } from "@services/log";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { FormUtil } from "@services/form-util";
import { InputHelper } from "@services/input-helper";
import { Subscription } from "rxjs";
import { Utils } from "@services/utils";
import { ApiMethod } from "@app/enum";
import { AttachedFileUtil } from "@services/attached-file-util";
import { ActivatedRoute } from "@angular/router";

declare module '@angular/forms/forms' {
  interface AbstractControl {
    validate(): void;
  }
}

AbstractControl.prototype.validate = function(this: FormGroup): void {
  this.markAllAsTouched();
  for (const key in this.controls) {
    const formElement = this.get(key);
    if (formElement instanceof FormControl) {
      formElement.updateValueAndValidity();
    } else if (formElement instanceof FormGroup) {
      formElement.validate();
    }
    else if(formElement instanceof FormArray){
      for(let childElement of formElement.controls){
        childElement.validate()
      }
    }
  }
};
@Directive()
export abstract class BaseFormItem<T = any>
  extends BaseComponent
  implements ComponentCheckCanQuit
{
  private _model: T;
  @Input() set model(value) {
    this._model = value;
  }
  get model() {
    return this._model;
  }

  isEditing: boolean = false;
  onProgress: boolean = false;
  formInput: FormGroup;
  fileToUpload: { [key: string]: File } = {};
  protected formGroupDeclaration: FormGroupDeclaration = {};
  protected _formInputKeys = [];
  get formInputKeys(): Array<string> {
    return this._formInputKeys;
  }
  private apiSubscription: Subscription;

  // if true -> create form inside ngOnInit
  // if not -> wait after fetch data (BaseDetail)
  get shouldCreateFormImmediately() {
    return true;
  }

  skipBindingAfterCreate = false;

  constructor(protected activatedRoute: ActivatedRoute = null) {
    super(activatedRoute);
  }

  ngOnInit() {
    super.ngOnInit();
    for (let key of Object.keys(this.formGroupDeclaration)) {
      let d = this.formGroupDeclaration[key];
      if (d.type == "uploadFile") {
        d.isChanged = this.isChangedFile.bind(this, key);
        if (d.required) {
          FormUtil.addValidator(d, this.requireAttachFile.bind(this, key));
        }
      }
    }
    if (this.shouldCreateFormImmediately) {
      this.createFormInput(this.model);
      this.setEnableFormGroup(true); // make sure all readOnly fields will be disabled after created.
      if (this.isAdminReadOnlyRole) {
        this.setEnableFormGroup(false);
      }
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.apiSubscription?.unsubscribe();
  }

  protected getApiUrl(method: ApiMethod = ApiMethod.get): string {
    throw Error("Method should be overridden.");
  }

  protected reset() {
    this.fileToUpload = {};
    if (this.model) {
      for (let key of this.formInputKeys) {
        if (
          this.formGroupDeclaration[key].type == "uploadFile" &&
          this.model[key] &&
          this.model[key].deleted
        ) {
          delete this.model[key].deleted;
        }
      }
    }
  }

  // Gọi hàm này trước khi bind model vào form
  // Mục đích để tạo 1 số trường dữ liệu mà API ko trả về
  protected beforeBindModel(model: T): any {
    return model;
  }

  protected afterBindModel(model?: T) {}

  protected bindDataModel(model: T) {
    FormUtil.bindData(
      this.formInput,
      this.beforeBindModel(model),
      this.formGroupDeclaration
    );
    this.afterBindModel(model);
    this.setEnableFormGroup(false);
  }

  public formData_JSON(isCreateNew: boolean) {
    return this.getFormData_JSON(isCreateNew);
  }

  protected getFormData_JSON(isCreateNew: boolean) {
    let originalData = isCreateNew ? undefined : this.model;
    return FormUtil.getFormGroupData(
      this.formInput,
      this.formGroupDeclaration,
      originalData
    );
  }

  protected getFormItemValue(key: string) {
    return this.getItemValue(key);
  }

  protected getFormData(isCreateNew: boolean): T | FormData {
    let jsonData = this.getFormData_JSON(isCreateNew);
    let fileKeys = Object.keys(this.fileToUpload);
    if (fileKeys.length > 0) {
      let formData = new FormData();
      for (let key of fileKeys) {
        if (this.fileToUpload[key] instanceof File) {
          formData.append(
            key,
            this.fileToUpload[key],
            this.fileToUpload[key].name
          );
        } else if (this.model && this.model[key] && this.model[key].deleted) {
          jsonData[key] = null;
        }
      }
      formData.append("params", JSON.stringify(jsonData));
      return formData;
    } else {
      return jsonData;
    }
  }

  get isCreateNew(): boolean {
    return !this.model || !(<any>this.model)._id;
  }
  get isEditOrCreate(): boolean {
    return this.isCreateNew || this.isEditing;
  }

  get formGroupError() {
    return FormUtil.validateFormGroup(this.formInput);
  }

  validateForm() {
    const err = FormUtil.validateFormGroup(this.formInput, {markFormError: true, includeErrorKey: true});
    if (!err) return;
    const key = Object.keys(err)[0];
    const label = this.getLabel(key);
    if (!label) {
      return err;
    }
    if (err[key]?.required == true) {
      return `${label} is required`;
    }
    return err;
  }

  get needUpdate() {
    if (!this.formInput || !Utils.isObjectNotEmpty(this.formInput.controls))
      return false;
    let err = this.formGroupError;
    if (err) {
      return false; // nếu có lỗi thì ko update gì cả
    }
    // Tạo mới thì chỉ cần check đến đây là đủ
    if (this.isCreateNew) {
      // Nếu là tạo mới, mà form input ko có lỗi gì thì cho save
      return true;
    }
    // Edit thì phải so sánh với các giá trị cũ xem có khác nhau ko
    if (!this.model) {
      return false;
    }
    return this.isFormDataChanged();
  }

  /**
   * Dùng cho các form có validate show error tại field.
   * Cho phép user click button để hiển thị lỗi tại field. Nên không cần check lỗi của form nữa.
   */
  get needUpdateV2() {
    if (!this.formInput || !Utils.isObjectNotEmpty(this.formInput.controls))
      return false;
    // let err = this.formGroupError;
    // console.log(err)
    // if (err) {
    //   return false; // nếu có lỗi thì ko update gì cả
    // }
    // Tạo mới thì chỉ cần check đến đây là đủ
    if (this.isCreateNew) {
      // Nếu là tạo mới, mà form input ko có lỗi gì thì cho save
      return true;
    }
    // Edit thì phải so sánh với các giá trị cũ xem có khác nhau ko
    if (!this.model) {
      return false;
    }
    return this.isFormDataChanged();
  }

  protected isFormDataChanged() {
    return FormUtil.isFormGroupChanged(
      this.formInput,
      this.model,
      this.formGroupDeclaration
    );
  }

  @HostListener("window:beforeunload") // allows us to guard against browser refresh, close ...
  canQuit(): string {
    if (this.onProgress) {
      return "Form data is being submited. Are you sure you want to quit?";
    }
    if (this.isEditing) {
      if (this.isFormDataChanged()) {
        return this.text(KeyLang.Txt_SaveBeforeRoute);
      }
    } else if (this.isCreateNew) {
      if (
        FormUtil.isFormGroupHasData(this.formInput, this.formGroupDeclaration)
      ) {
        return this.text(KeyLang.Txt_SaveBeforeRoute);
      }
    }
    return null;
  }

  // for cdk drag & drop
  drop(event: CdkDragDrop<any[]>, arr: Array<any>) {
    if (event.previousIndex == event.currentIndex) {
      return;
    }
    moveItemInArray(arr, event.previousIndex, event.currentIndex);
  }

  canDragDrop(list: Array<any>) {
    return list && list.length > 1 /*&& this.isEditing*/;
  }

  onBtnSave() {
    if (!this.needUpdate) {
      return;
    }
    if (this.isCreateNew) {
      this.createData();
    } else {
      this.updateData();
    }
  }

  onBtnEdit() {
    if (!this.isEditing) {
      this.isEditing = true;
      this.setEnableFormGroup(true);
    }
  }

  protected setEnableFormGroup(enabled: boolean) {
    FormUtil.setEnableFormGroup(
      this.formInput,
      this.formGroupDeclaration,
      enabled
    );
  }

  protected onCreateSuccess(resp) {
    // this.fileToUpload = {};
    if (resp && resp.ui_message) {
      this.showInfo(resp.ui_message);
    } else {
      this.showInfo("New item has been created successfully.");
    }
  }

  protected onCreateFailure(err) {
    this.showErr(err);
  }

  protected onUpdateSuccess(resp) {
    // this.fileToUpload = {};
    if (resp && resp.ui_message) {
      this.showInfo(resp.ui_message);
    } else {
      this.showInfo("The item has been updated successfully.");
    }
  }

  protected onUpdateFailure(err) {
    this.showErr(err);
  }

  protected startProgress() {
    this.onProgress = true;
  }

  protected stopProgress() {
    this.onProgress = false;
  }

  protected buildUrl(method: ApiMethod): string {
    let url = this.getApiUrl(method);
    if (!url) return url;
    let id = (<any>this.model)?._id;
    if (id) {
      url = `${url}/${id}`;
    }
    return url;
  }

  protected createData() {
    let params = this.getFormData(true);
    if (!params) {
      return Log.e("getFormData must be overridden");
    }
    let url = this.buildUrl(ApiMethod.post);
    if (!url) {
      return Log.e("url is not provided");
    }
    let obs;
    if (params instanceof FormData) {
      obs = this.api.postFormData(url, params, { observe: "response" });
    } else {
      obs = this.api.POST(url, params, { observe: "response" });
    }
    this.startProgress();
    this.apiSubscription?.unsubscribe();
    this.apiSubscription = obs.subscribe(
      (resp) => {
        this.fileToUpload = {};
        let httpCode = resp.status;
        let body = resp.body;
        Log.d(`createData done, statusCode: ${httpCode}, resp: `, body);
        if (body && body.data && !this.skipBindingAfterCreate) {
          this.model = body.data;
          this.bindDataModel(this.model);
        }
        this.onCreateSuccess(body);
        this.stopProgress();
      },
      (err) => {
        Log.e("createData error: ", err);
        this.onCreateFailure(err);
        this.stopProgress();
      }
    );
  }

  protected updateData() {
    let params = this.getFormData(false);
    if (!params) {
      return Log.e("getFormData must be overridden");
    }
    let url = this.buildUrl(ApiMethod.put);
    if (!url) {
      return Log.e("url is not provided");
    }
    let obs;
    if (params instanceof FormData) {
      obs = this.api.putFormData(url, params, { observe: "response" });
    } else {
      obs = this.api.PUT(url, params, { observe: "response" });
    }
    this.startProgress();
    this.apiSubscription?.unsubscribe();
    this.apiSubscription = obs.subscribe(
      (resp) => {
        let httpCode = resp.status;
        let body = resp.body;
        if (body.ui_message) {
          this.showDialog(body.ui_message);
        }
        Log.d(`updateData done, statusCode: ${httpCode}, resp: `, body);
        this.reset();
        this.model = body.data;
        this.bindDataModel(this.model);
        this.onUpdateSuccess(body);
        this.stopProgress();
      },
      (err) => {
        this.onUpdateFailure(err);
        this.stopProgress();
      }
    );
  }

  protected createFormInput(bindData = undefined) {
    Log.d(
      "createFormInput bindData: ",
      bindData,
      " -- formGroupDeclaration: ",
      this.formGroupDeclaration
    );
    if (bindData) {
      this.formInput = FormUtil.createFormGroup(
        this.formGroupDeclaration,
        this.beforeBindModel(bindData)
      );
      this.afterBindModel(bindData);
    } else {
      this.formInput = FormUtil.createFormGroup(this.formGroupDeclaration);
    }
    this.updateFormInputKeys();
  }

  protected updateFormInputKeys() {
    this._formInputKeys = Object.keys(this.formInput.controls);
  }

  protected addItemToFormGroup(
    itemKey: string,
    declaration: FormControlDeclaration,
    bindData = undefined
  ) {
    if (this.formGroupDeclaration[itemKey]) {
      throw Error(`${itemKey} has already existed in formGroupDeclaration`);
    }
    this.formGroupDeclaration[itemKey] = declaration;
    FormUtil.addItemToFormGroup(this.formInput, itemKey, declaration, bindData);
    this.updateFormInputKeys();
  }

  protected removeFormGroupItem(itemKey: string) {
    this.formInput.removeControl(itemKey);
    this.formGroupDeclaration[itemKey] = undefined;
    this.updateFormInputKeys();
  }

  protected removeFormGroupItems(itemKeys: Array<string>) {
    for (let itemKey of itemKeys) {
      this.formInput.removeControl(itemKey);
      this.formGroupDeclaration[itemKey] = undefined;
    }
    this.updateFormInputKeys();
  }

  protected isChangedFile(key) {
    if (this.fileToUpload[key] instanceof File) {
      return true;
    }
    if (this.model[key] && this.model[key].deleted) {
      return true;
    }
    return false;
  }

  protected requireAttachFile(key) {
    if (this.fileToUpload[key] instanceof File) {
      return null;
    }
    if (this.model && this.model[key]) {
      return null;
    }
    return { require: true };
  }

  onFileSelected(key, files) {
    this.fileToUpload[key] = files[0];
    this.formInput.get(key)?.updateValueAndValidity();
  }

  hasAttachedFile(key: string) {
    return !!this.fileToUpload[key];
  }

  getFileDesc(key: string | File): string {
    if (key instanceof File) {
      return AttachedFileUtil.fileDesc(key);
    }
    if (!this.fileToUpload[key]) return "";
    return `${this.fileToUpload[key].name} (${this.displayFileSize(
      this.fileToUpload[key].size
    )})`;
  }

  delFile(key, inputElement: HTMLInputElement) {
    if (!this.fileToUpload[key]) return;
    this.confirmDeletion({
      message: `Delete file ${this.fileToUpload[key].name}?`,
      txtBtnOk: "Delete",
      fnOk: () => {
        this.fileToUpload[key] = undefined;
        this.formInput.get(key)?.updateValueAndValidity();
        inputElement.value = "";
      },
    });
  }

  getAttachedFileUrl(key) {
    return super.attachedFileUrl(this.model[key]);
  }

  getAttachedFileDesc(key) {
    if (!this.model[key]) return "";
    return `${this.model[key].name} (${this.displayFileSize(
      this.model[key].size
    )})`;
  }

  delAttachedFile(key) {
    if (!this.model[key].deleted) {
      this.confirmDeletion({
        message: `Delete file ${this.model[key].name}?`,
        txtBtnOk: "Delete",
        fnOk: () => {
          this.model[key].deleted = true;
        },
      });
    } else {
      this.model[key].deleted = false;
    }
  }

  getChildFormByKey(key: string): AbstractControl {
    let arr = key.split(".");
    let currentControl: AbstractControl = this.formInput;
    for (let i = 0; i < arr.length; i++) {
      if (!currentControl) {
        return null;
      }
      let match = arr[i].match(/\[[0-9]+\]$/);
      let subKey = arr[i];
      if (match && match[0]) {
        subKey = arr[i].substring(0, match.index);
        let index = Number(match[0].replace(/[^0-9]/g, ""));
        currentControl = (<FormArray>currentControl.get(subKey)).at(index);
      } else {
        currentControl = currentControl.get(subKey);
      }
    }
    return currentControl;
  }

  // key can be like this: 'customer.warehouses[2].address.city'
  getChildFormInfoByKey(key: string): {
    formControl: AbstractControl;
    declaration: FormControlDeclaration;
  } {
    let arr = key.split(".");
    let currentControl: AbstractControl = this.formInput;
    let currentDeclaration: FormGroupDeclaration | FormControlDeclaration =
      this.formGroupDeclaration;
    for (let i = 0; i < arr.length; i++) {
      if (!currentControl) {
        return null;
      }
      let match = arr[i].match(/\[[0-9]+\]$/);
      let subKey = arr[i];
      if (match && match[0]) {
        subKey = arr[i].substring(0, match.index);
        let index = Number(match[0].replace(/[^0-9]/g, ""));
        currentControl = (<FormArray>currentControl.get(subKey)).at(index);
      } else {
        currentControl = currentControl.get(subKey);
      }
      currentDeclaration = currentDeclaration[subKey];
      if (
        currentControl instanceof FormGroup ||
        currentControl instanceof FormArray
      ) {
        currentDeclaration = currentDeclaration.childItem;
      }
    }
    return {
      formControl: <FormControl>currentControl,
      declaration: <FormControlDeclaration>currentDeclaration,
    };
  }

  setItemValue(key: string, value) {
    let item = this.getChildFormInfoByKey(key);
    if (!item) {
      return;
    }
    FormUtil.bindData(item.formControl, value, item.declaration);
  }

  //set nhiều giá trị mặc định cho form mà không cần khởi tạo lại form
  setFormValues(data) {
    Object.keys(data).map((itemKey) => {
      if (!this.formGroupDeclaration[itemKey]) return;
      this.setItemValue(itemKey, data[itemKey]);
    });
  }

  getItemValue(key: string): any {
    let item = this.getChildFormInfoByKey(key);
    if (!item) {
      return null;
    }
    return FormUtil.getFormItemData(item.formControl, item.declaration);
  }

  isDate(key: string): boolean {
    return FormUtil.getTypeForKey(key, this.formGroupDeclaration) == "date";
  }

  isUploadFile(key: string): boolean {
    return (
      FormUtil.getTypeForKey(key, this.formGroupDeclaration) == "uploadFile"
    );
  }

  isString(key: string): boolean {
    return FormUtil.getTypeForKey(key, this.formGroupDeclaration) == "string";
  }

  isBool(key: string): boolean {
    return FormUtil.getTypeForKey(key, this.formGroupDeclaration) == "boolean";
  }

  isFormGroup(key: string): boolean {
    return (
      FormUtil.getTypeForKey(key, this.formGroupDeclaration) == "formGroup"
    );
  }

  isRequired(key: string): boolean {
    return FormUtil.isRequired(key, this.formGroupDeclaration);
  }

  isHidden(key: string): boolean {
    return FormUtil.isHidden(key, this.formGroupDeclaration);
  }

  isReadOnly(key: string): boolean {
    return FormUtil.isReadOnly(key, this.formGroupDeclaration);
  }

  isMultiline(key: string): boolean {
    return FormUtil.isMultiline(key, this.formGroupDeclaration);
  }

  getLabel(key: string): string {
    return FormUtil.getLabelForKey(key, this.formGroupDeclaration);
  }

  getPlaceHolder(key: string): FormControlPlaceHolder {
    return (
      FormUtil.getItemByKey(key, this.formGroupDeclaration)?.placeHolder || ""
    );
  }

  // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#input_types
  getInputType(key: string): string {
    return (
      FormUtil.getItemByKey(key, this.formGroupDeclaration)?.inputType || "text"
    );
  }

  getItemByKey(key: string): FormControl {
    return this.getChildFormInfoByKey(key).formControl as FormControl;
  }

  isFormControlExists(key: string): boolean {
    return this.getChildFormInfoByKey(key)?.formControl != null;
  }

  fullKey(...args): string {
    return args.join(".");
  }

  getFormArrayLength(key: string): number {
    let fa = this.getFormArray(key);
    return fa ? fa.length : 0;
  }

  getFormArray(key: string): FormArray {
    return <FormArray>this.formInput.get(key);
  }

  getArrayControls(key: string): Array<AbstractControl> {
    let f = this.getFormArray(key);
    if (f) return f.controls || [];
    return [];
  }

  getArrayControlsOfArray(
    key1: string,
    index: number,
    key2: string
  ): Array<AbstractControl> {
    let f = <FormArray>this.getFormArray(key1).at(index).get(key2);
    if (f) return f.controls || [];
    return [];
  }

  addItemToFormArray(key: string, bindData = null) {
    let fa = this.getFormArray(key);
    let declaration = this.formGroupDeclaration[key];
    let f = FormUtil.createChildItem(declaration, bindData);
    fa.push(f);
  }
  addItemToFormArrayOfArray(
    key1: string,
    index: number,
    key2: string,
    bindData = null
  ) {
    let fa = <FormArray>this.getFormArray(key1).at(index).get(key2);
    let declaration = this.formGroupDeclaration[key1].childItem[key2];
    let childItem = declaration.childItem;
    if (childItem) {
      let fg = FormUtil.createFormGroup(
        <FormGroupDeclaration>childItem,
        bindData
      );
      fa.push(fg);
      FormUtil.setEnableFormGroup(fg, <FormGroupDeclaration>childItem, true);
    } else {
      let fc = new FormControl();
      fa.push(fc);
    }
  }
  removeItemInFormArray(key: string, index: number) {
    let fa = this.getFormArray(key);
    fa.removeAt(index);
  }

  getChildItemKeys(key: string) {
    let child = this.formGroupDeclaration[key]?.childItem;
    if (child) return Object.keys(child);
    return [];
  }

  // event.inputType: insertFromPaste | insertText | historyUndo | deleteContentBackward
  // handle text changed by copy paste
  onInputChanged(event, key) {
    if (key === "phone") {
      InputHelper.handleInputChangePhone(
        event,
        <FormControl>this.formInput.get(key)
      );
    }
    if(key === "otp") {
      InputHelper.handleInputChangeNumberOnly(
        event,
        <FormControl>this.formInput.get(key)
      );
    }
  }

  onInputKeyPress(event: KeyboardEvent, key) {
    if (["phone", "otp"].includes(key)) {
      // Allow number only
      return InputHelper.handleInputKeyPressNumberOnly(event);
    }
    return true;
  }

  onInputFocusOut(event, key) {}

  onInputFocusIn(event, key) {}

  // style dialog for landing page
  protected showSuccessV1(message: string) {
    this.modalService.create({
      nzContent: message,
      nzClosable: false,
      nzMaskClosable: false,
      nzCentered: true,
      nzOkText: "OK",
      nzCancelText: null,
      nzClassName: "dialog-v1",
    });
  }
}
