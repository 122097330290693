import { NgModule } from "@angular/core";
import TabsComponent from "./tabs/tabs.component";
import TabComponent from "./tab/tab.component";
import {CommonModule} from '@angular/common'
import { NzIconModule } from "ng-zorro-antd/icon";

@NgModule({
  imports:[
    CommonModule,
    NzIconModule,
  ],
  declarations: [TabsComponent, TabComponent],
  exports: [TabsComponent, TabComponent]
})
export class TabsModule {}
