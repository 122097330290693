import { Component, Input, Output, EventEmitter } from '@angular/core';

// should be put inside <form></form>
@Component({
  selector: '[detail-footer-buttons]',
  templateUrl: './detail-footer-buttons.html',
  styleUrls: ['./detail-footer-buttons.scss'],
})
export class DetailFooterButtons {
  @Input() isCreateNew = false;
  @Input() isEditing = false;
  @Input() onProgress = false;
  @Input() needUpdate = false;
  @Input() labelButtonCreate: string = 'Create new';
  @Input() quotingInProgress = false;
  @Input() shouldShowBtnQuote = false;
  @Input() shouldShowBtnDelete = false;
  @Input() shouldDisableBtnEdit = false;
  @Input() shouldShowBtnComeBack = false;
  @Input() labelButtonComeBack: string = 'Back';
  @Input() allowEdit = true;

  get shouldShowBtnEdit() { return this.allowEdit && !this.isEditing && !this.isCreateNew }
  get shouldShowBtnSave() { return this.isCreateNew || this.isEditing }
  get shouldShowBtnCancel() { return this.shouldShowBtnSave && !this.isCreateNew }

  @Output() onEdit: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSave: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() onQuote: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDelete: EventEmitter<any> = new EventEmitter<any>();
  @Output() onBack: EventEmitter<any> = new EventEmitter<any>();

  onBtnEdit() {
    this.onEdit.emit();
  }
  
  onBtnSave() {
    this.onSave.emit();
  }
  
  onBtnCancel() {
    this.onCancel.emit();
  }

  onBtnQuote() {
    this.onQuote.emit();
  }

  onBtnDelete() {
    this.onDelete.emit();
  }

  onBtnComeBack() {
    this.onBack.emit();
  }
}