<button nz-button (click)="onBtnEdit()" nzType="primary" *ngIf="shouldShowBtnEdit" [disabled]="shouldDisableBtnEdit">
  <i nz-icon nzType="edit" nzTheme="outline"></i>
  Edit
</button>
<button nz-button (click)="onBtnSave()" nzType="primary" *ngIf="shouldShowBtnSave"
  [disabled]="onProgress || !needUpdate" [nzLoading]="onProgress">
  <ng-container *ngIf="isCreateNew">
    {{labelButtonCreate}}
  </ng-container>
  <ng-container *ngIf="!isCreateNew">
    <i nz-icon nzType="save" nzTheme="outline"></i>
    Save
  </ng-container>
</button>
<button nz-button (click)="onBtnCancel()" [disabled]="onProgress" *ngIf="shouldShowBtnCancel">
  <i nz-icon nzType="close" nzTheme="outline"></i>
  Cancel
</button>
<button nz-button (click)="onBtnQuote()" [disabled]="quotingInProgress" *ngIf="shouldShowBtnQuote">
  <i *ngIf="!quotingInProgress" nz-icon nzType="calculator" nzTheme="outline"></i>
  <i *ngIf="quotingInProgress" nz-icon nzType="loading" nzTheme="outline"></i>
  Quote
</button>
<button nz-button [nzLoading]="onProgress" nzDanger *ngIf="shouldShowBtnDelete" nz-popconfirm
  nzPopconfirmTitle="Are you sure to delete this item?" (nzOnConfirm)="onBtnDelete()" nzCancelText="Cancel"
  nzOkText="Yes">
  <i nz-icon nzType="delete" nzTheme="outline"></i>
  Delete
</button>
<button nz-button *ngIf="shouldShowBtnComeBack" [disabled]="onProgress" (click)="onBtnComeBack()">
  {{labelButtonComeBack}}
</button>